import { Link } from 'gatsby';
import React from 'react';

import Section from '../index';
import SummaryItem from '../../summary-item';

const SectionProjects = ({ projects }) => {
  if (!projects.length) return null;

  return (
    <Section title="Hobby Projects">
      {/* SEO can be improved here */}
      {[...projects].slice(0, 3).map((project) => (
        <SummaryItem
          key={project.name}
          name={project.name}
          description={project.description}
          link={project.link}
          github={project.github}
        />
      ))}
      {projects.length >= 3 && (
        <Link className="text-sm text-gray-500 hover:text-black dark:text-gray-400 dark:hover:text-gray-500" to="/projects">
          View more projects &rarr;
        </Link>
      )}
    </Section>
  );
};

export default SectionProjects;
