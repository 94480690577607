import React from 'react';

import Section from '../index';

const SectionAbout = ({ about }) => {
  return (
    <Section title="About Me">
      <div className="mb-1">
        <p dangerouslySetInnerHTML={{__html: about}}/>
      </div>
    </Section>
  );
};

export default SectionAbout;
